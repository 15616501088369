import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import {Alert, Carousel, Container, Row, Col, Jumbotron, Card} from 'react-bootstrap'
import Logo from '../components/logo'
import Img from "gatsby-image"
import Divider from "../components/divider"
import { FaQuestion } from "react-icons/fa"
import ReactMarkdown from 'react-markdown'

const Page = ({ data }) => {
    const { site, home: {frontmatter: home}, operation } = data

    return (
      <Layout>
        <SEO title="Lieu de vie dédié aux familles" />
        <Container className="p-0" fluid>
            <Jumbotron className="p-0 bg-green-light" fluid>
                <Row className="justify-content-center p-0" noGutters>
                    <Col lg={6} md={8} className="p-0">
                        <Carousel fade={true} indicators={false} interval={2000}>
                            {
                                home.carousel.map((pic, indx) => {
                                    return (
                                        <Carousel.Item key={indx} >
                                            <Img 
                                            fluid={pic.childImageSharp.fluid} alt={pic.name} className="h-100"
                                            />
                                        </Carousel.Item>
                                        ) 
                                    }
                                )
                            }
                        </Carousel>
                    </Col>
                </Row>
            </Jumbotron>
        </Container>

        <Container>
            <Alert variant="green" className="mb-2 text-center">
                <ReactMarkdown parserOptions={{ commonmark: true }} source={home.info} />
            </Alert>

            <Logo type="people" height="11rem" className="mx-auto d-block mb-4 mt-3"/>

            <h2 className="text-center text-green-dark">Chez {site.siteMetadata.title}, on vous propose : </h2>
            <div >
                <Row className="row-cols-1 row-cols-md-4 align-self-center my-4" >

                    {
                        home.proposals.map((proposal, indx) => {
                            return (
                                <Col key={indx}>
                                    <Link to={proposal.link} >
                                        <Card border="light" className={"card-hover"} >
                                            <Card.Header style={{ height: '5rem' }} className={"h5 text-center"}>
                                                {proposal.title}
                                            </Card.Header>
                                            <Card.Body className={"card-body"} style={{ height: '15rem' }}>
                                                <Img className={"hidden-on-hover"}
                                                     fluid={proposal.image.childImageSharp.fluid} alt={proposal.title}
                                                />
                                                <div className="reveal p-4">
                                                    <ReactMarkdown parserOptions={{ commonmark: true }} source={proposal.description} />
                                                </div>
                                            </Card.Body>
                                        </Card>
                                    </Link>
                                </Col>
                            )
                        })
                    }
                </Row>
            </div>

            <Divider />

            <div className="bg-green-light rounded my-4 mt-2 py-4">
                <h3 className="text-center text-green-dark mb-4">Comment ça fonctionne ?</h3>
                <Row className="justify-content-center text-justify align-items-center">
                    <Col md={4} sm={8} className="mr-0">
                        <FaQuestion size="4em" className="mx-auto d-block"></FaQuestion>
                        <Img fluid={operation.frontmatter.intro_image.childImageSharp.fluid} alt="animal" />
                    </Col>
                    <Col md={6} className="h5">
                        <ReactMarkdown parserOptions={{ commonmark: true }} source={operation.frontmatter.intro} />
                        <p>
                            Pour plus d'informations, vous pouvez consulter la page <Link to='/fonctionnement'>Fonctionnement et tarifs</Link>
                        </p>
                        <h4 className="text-center text-green-dark">
                            <Link to={"/faq"}>
                                Vous avez encore des questions ? N'hésitez pas à aller consulter la FAQ :)
                            </Link>
                        </h4>

                    </Col>
                </Row>
            </div>

        </Container>
      </Layout>
    )
  }  

export default Page

export const pageQuery = graphql`
    query($id: String) {
        home:mdx(id: { eq: $id }) {
            frontmatter {
                info
                operation
                carousel {
                    childImageSharp {
                        fluid(maxHeight: 480) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
                proposals {
                    title
                    image {
                        childImageSharp {
                            fluid(maxWidth: 800, maxHeight: 800) {
                                ...GatsbyImageSharpFluid_withWebp
                            }
                        }
                    }
                    description
                    link
                }
            }
        }
        site {
            siteMetadata {
              title
            }
        }
        operation:mdx(frontmatter: {slug: {eq: "/fonctionnement"}}) {
            frontmatter {
                intro
                intro_image {
                    childImageSharp {
                        fluid(maxWidth: 300) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
            }
        }

    }`
