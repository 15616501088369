import React from 'react'

export default ({size}) => {
    return (
        <hr style={{
            marginTop: "1.5rem",
            marginBottom: "1.5rem",
            border: 0,
            borderTop: "1px solid rgba(103, 153, 146, 0.7)",
        }} />
    )
}
